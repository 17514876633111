import React from "react"
import PropTypes from "prop-types"
import Image from "gatsby-image"
import SEO from "../components/SEO"
import {
  FaGithubSquare,
  FaShareSquare,
  FaAngleDoubleRight,
} from "react-icons/fa"

const Project = ({ description, title, giturl, stack, url, image, index }) => {
  return (
    <article className="project">
      <SEO title={title} description={description} />

      {image && (
        <Image fluid={image.childImageSharp.fluid} className="project-img" />
      )}
      <div className="project-info">
        <span className="project-number">0{index + 1}</span>
        <h3>{title}</h3>
        {description.map(item => {
          return (
            <div key={item.id} className="job-desc">
              <FaAngleDoubleRight className="job-icon"></FaAngleDoubleRight>
              <p>{item.name}</p>
            </div>
          )
        })}
        {/* <p className="project-desc">{description}</p> */}
        <div className="project-stack">
          {stack.map(item => {
            return (
              <span key={item.id}>
                <SEO title={item.title} description={item.title} />
                {item.title}
              </span>
            )
          })}
        </div>
        <div className="project-links">
          <a href={giturl}>
            <FaGithubSquare className="project-icon" />
          </a>
          <a href={url}>
            <FaShareSquare className="project-icon" />
          </a>
        </div>
      </div>
    </article>
  )
}

Project.propTypes = {}

export default Project
