import React from "react"
import { FaMobileAlt, FaDesktop, FaDatabase } from "react-icons/fa"
export default [
  {
    id: 1,
    icon: <FaMobileAlt className="service-icon" />,
    title: "Mobile App Development",
    text: `I develop both cross-platform and native mobile applications for Android and iOS.`,
  },
  {
    id: 2,
    icon: <FaDesktop className="service-icon" />,
    title: "Desktop App Development",
    text: `I have a lot of experience of working with desktop application environment to meet business needs.`,
  },
  {
    id: 3,
    icon: <FaDatabase className="service-icon" />,
    title: "Backend Services",
    text: `I work with Node.js and SQL / NoSQL databases to develop robust and scalable and robust backend systems.`,
  },
]
